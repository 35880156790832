import React from "react"

import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const Books = props => {
  const { data } = props
  const { basicPythonCover } = data
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="My books - Basic Python" />
      <div className="container py-8">
        <div className="flex flex-wrap">
          <div className="w-full">
            <div className="md:w-12/12 mb-8">
              <h1 className="mb-4 font-bold text-3xl no-underline">
                Basic Python
              </h1>
              <p>
                This book will talk about programming with Python. The goal for
                this is to be guide that will be simple to read and follow, with
                clear language and clear examples. No Python history, no
                philoshophy or anything of the type.
              </p>
              <p className="mt-3">
                This book requires really basic knowledge on how a computer
                works and how to use the terminal in your operational system.
              </p>
            </div>
          </div>

          <div className="w-full">
            <h2 className="mt-4 mb-4 font-bold text-blue-500 text-xl">
              Table of contents
            </h2>
          </div>
          <div className="w-full">
            <ol className="list-inside list-decimal">
              <Link to="introduction">
                <li className="text-blue-500">Introduction</li>
              </Link>
              <Link to="first-program-print-comments-and-data-types">
                <li className="text-blue-500">
                  First program - Print, comments and data types
                </li>
              </Link>
              <Link to="mathematical-operators-and-signs">
                <li className="text-blue-500">
                  Mathematical operators and signs
                </li>
              </Link>
              <Link to="variables">
                <li className="text-blue-500">Variables</li>
              </Link>
              <Link to="string-text">
                <li className="text-blue-500">Strings (text)</li>
              </Link>
              <Link to="lists">
                <li className="text-blue-500">Lists</li>
              </Link>
              <Link to="tuples">
                <li className="text-blue-500">Tuples</li>
              </Link>
              <Link to="dictionaries">
                <li className="text-blue-500">Dictionaries</li>
              </Link>
              <Link to="sets">
                <li className="text-blue-500">Sets</li>
              </Link>
              <Link to="control-structures">
                <li className="text-blue-500">Control structures</li>
              </Link>
              <li>Functions</li>
              <li>Modules</li>
              <li>Classes and object oriented programming</li>
              <li>Date and time</li>
              <li>Exceptions</li>
              <li>Files</li>
              <li>Final words</li>
            </ol>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    basicPythonCover: file(absolutePath: { regex: "/capa-livro-python.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Books
